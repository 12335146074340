<template>
  <div class="justify-center">

      <Matcher :i18n_instructionMsg_key="'matchNavKalamoInstruction'"
      :matchList="match"
      :numberOfMatches="match.length"
      :fontSize="$vuetify.breakpoint.smAndUp? '15px':'10px'"/>

  </div>
</template>

<script>
import Matcher from './Matcher'

export default {
  components: {
    Matcher
  },

  methods: {

  },
  data: () => ({

    match: [
      {
        eng: 'He dada bhagawan ! mane koi pan dehdhari jivatmano kinchitmatra pan aham n dubhay, n dubhavay, ke dubhavva Pratye n anumoday evi param shakti aapo. Mane koi pan dehdhari jivatmano kinchitmatra pan aham n dubhay evi shyadvad vani, shyadvad vartan ane shyadvad manan karvani param shakti aapo. ',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી જીવાત્માનો કિંચિત્માત્ર પણ અહમ્ ન દુભાય, ન દુભાવાય કે દુભાવવા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો. મને કોઈ પણ દેહધારી જીવાત્માનો કિંચિત્માત્ર પણ અહમ્ ન દુભાય એવી સ્યાદવાદ વાણી, સ્યાદવાદ વર્તન અને સ્યાદવાદ મનન કરવાની પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी जीवात्मा का किंचित्मात्र भी अहम् न दुभे (दुःखे), न दुभाया (दुःखाया) जाये या दुभाने (दुःखाने) के प्रति अनुमोदना न की जाये, ऐसी परम शक्ति दो।मुझे किसी देहधारी जीवात्मा का किंचित्मात्र भी अहम् न दुभे, ऐसी स्याद्वाद वाणी, स्याद्वाद वर्तन और स्याद्वाद मनन करने की परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan dhrmanu kinchitmatra pan Praman n dubhay, n dubhavay, ke dubhavva pratye n Anumoday evi param shakti aapo. Mane koi pan dhrmanu kinchitmatra pan praman n dubhavay evi shyadvad vani, Shyadvad vartan ane shyadvad manan karvani param shakti aapo. ',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ ધર્મનું કિંચિત્માત્ર પણ પ્રમાણ ન દુભાય, ન દુભાવાય કે દુભાવવા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો. મને કોઈ પણ ધર્મનું કિંચિત્માત્ર પણ પ્રમાણ ન દુભાવાય એવી સ્યાદવાદ વાણી, સ્યાદવાદ વર્તન અને સ્યાદવાદ મનન કરવાની પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी धर्म का किंचित्मात्र भी प्रमाण न दुभे, न दुभाया जाये या दुभाने के प्रति अनुमोदना न की जाये, ऐसी परम शक्ति दो।मुझे किसी भी धर्म का किंचित्मात्र भी प्रमाण न दुभाया जाये ऐसी स्याद्वाद वाणी, स्याद्वाद वर्तन और स्याद्वाद मनन करने की परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan dehdhari updeshak, sadhu, sadhvi ke aacharyano avranvaad, apradh, avinay n karvani param shakti aapo.',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી ઉપદેશક, સાધુ, સાધ્વી કે આચાર્યનો અવર્ણવાદ, અપરાધ, અવિનય ન કરવાની પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी उपदेशक साधु, साध्वी या आचार्य का अवर्णवाद, अपराध, अविनय न करने की परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan dehdhari  jivatma pratye Kinchitmatra pan abhav, tiraskar kyarey pan n karay, n karavay ke karta pratye n anumoday  evi param shakti Aapo.',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી જીવાત્મા પ્રત્યે કિંચિત્માત્ર પણ અભાવ, તિરસ્કાર ક્યારેય પણ ન કરાય, ન કરાવાય કે કર્તા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો. કોઈ કઠોર ભાષા, તંતીલી ભાષા બોલે તો મને મૃદુ-ઋજુ ભાષા બોલવાની શક્તિઓ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी जीवात्मा के प्रति किंचित्मात्र भी अभाव, तिरस्कार कभी भी न किया जाये, न करवाया जाये या कर्ता के प्रति न अनुमोदित किया जाये, ऐसी परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan dehdhari jivatma sathe Kyarey pan kathor bhasha, tantili bhasha n bolay, n Bolavay ke bolva prtye n anumoday evi param shakti aapo. Koi kathor bhasha, tantili bhasha bole to mane mrudu-ruju bhasha bolvani shaktio aapo.',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી જીવાત્મા સાથે ક્યારેય પણ કઠોર ભાષા, તંતીલી ભાષા ન બોલાય, ન બોલાવાય કે બોલવા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी जीवात्मा के साथ कभी भी कठोर भाषा, तंतीली (हठीली) भाषा न बोली जाये, न बुलवाई जाये या बोलने के प्रति अनुमोदना न की जाये, ऐसी परम शक्ति दो। कोई कठोर भाषा, तंतीली भाषा बोले तो मुझे मृदु-ऋजु भाषा बोलने की शक्ति दो ।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan dehdhari  jivatma pratye stree-purush agar napunsak, game te lingdhari hoi, to tena sabandhi kinchitmatra pan vishay-vikar sabandhi dosho, icchao, cheshtao ke vichar sabandhi dosho n karay, n karavay, ke karta pratye n anumoday evi param shakti aapo. Mane nirantar nirvikar rehvani param shakti aapo.',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી જીવાત્મા પ્રત્યે સ્ત્રી-પુરુષ અગર નપુંસક, ગમે તે લિંગધારી હોય, તો તેના સંબંધી કિંચિત્માત્ર પણ વિષય-વિકાર સંબંધી દોષો, ઇચ્છાઓ, ચેષ્ટાઓ કે વિચાર સંબંધી દોષો ન કરાય, ન કરાવાય કે કર્તા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો. મને નિરંતર નિર્વિકાર રહેવાની પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी जीवात्मा के प्रति स्त्री, पुरुष या नपुंसक, कोई भी लिंगधारी हो, तो उसके संबंध में किचिंत्मात्र भी विषय-विकार संबंधी दोष, इच्छाएँ, चेष्टाएँ या विचार संबंधी दोष न किये जायें, न करवाये जायें या कर्ता के प्रति अनुमोदना न की जाये, ऐसी परम शक्ति दो। मुझे निरंतर निर्विकार रहने की परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane koi pan rasma lubdhapanu nkaray Evi shakti aapo. samrasi khorak levay evi param Shakti aapo',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ રસમાં લુબ્ધપણું ન કરાય એવી શક્તિ આપો. સમરસી ખોરાક લેવાય એવી પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी रस में लुब्धता न हो ऐसी शक्ति दो। समरसी आहार लेने की परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagwan ! mane dehdhari jivatmano Prtyaksh agar paroksh, jivant agar mrutyu pamelano, koino Kinchitmatra pan avrnavaad, apradh, avinay n karay, n Karavay ke karta pratye n anumoday  evi param shakti aapo.',
        guj: 'હે દાદા ભગવાન ! મને કોઈ પણ દેહધારી જીવાત્માનો પ્રત્યક્ષ અગર પરોક્ષ, જીવંત અગર મૃત્યુ પામેલાનો, કોઈનો કિંચિત્માત્ર પણ અવર્ણવાદ, અપરાધ, અવિનય ન કરાય, ન કરાવાય કે કર્તા પ્રત્યે ન અનુમોદાય એવી પરમ શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे किसी भी देहधारी जीवात्मा का प्रत्यक्ष अथवा परोक्ष, जीवित अथवा मृत, किसी का किंचित्मात्र भी अवर्णवाद, अपराध, अविनय न किया जाये, न करवाया जाये या कर्ता के प्रति अनुमोदना न की जायें, ऐसी परम शक्ति दो।'
      },
      {
        eng: 'He dada bhagawan ! mane jagat kaliyan karvanu nimitt Banvani param shakti aapo, shakti aapo, shakti aapo.',
        guj: 'હે દાદા ભગવાન ! મને જગતકલ્યાણ કરવાનું નિમિત્ત બનવાની પરમ શક્તિ આપો, શક્તિ આપો, શક્તિ આપો.',
        hindi: 'हे दादा भगवान ! मुझे जगत कल्याण करने में निमित्त बनने की परम शक्ति दो, शक्ति दो, शक्ति दो।'
      }

    ]

  })
}
</script>
<style>

</style>
